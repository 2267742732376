.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 35px;
  position: relative;

  .more,
  .guide {
    cursor: pointer;
  }

  .guide {
    &.active {
      filter: invert(19%) sepia(97%) saturate(7463%) hue-rotate(358deg) brightness(105%) contrast(105%);
    }
  }

  &:not(&.collapse) {
    .guide {
      position: absolute;
      top: 15px;
      left: 22px;
      font-size: 35px;
    }
  }

  &.collapse {
    justify-content: space-between;
    height: 12px;

    .guide {
      display: flex;
      align-items: center;
      font-size: 24px;
    }

    .more {
      padding-top: 5px;
      font-size: 20px;
    }
  }
}
