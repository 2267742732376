@use 'style' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  gap: 10px;
  cursor: pointer;

  .text {
    flex: 1;
    padding-top: 2px;
    font-size: 15px;
    line-height: 12px;

    &.picked {
      color: $picked;
    }

    &.used {
      color: $used;
    }
  }
}
