.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.row {
    gap: 10px;
  }

  &.column {
    flex-direction: column;
    gap: 20px;
  }
}
