@use 'style' as *;

.container {
  aspect-ratio: 1 / 1;
  background-color: white;
  border: 1px solid black;
  position: relative;
  overflow: hidden;

  &.picked {
    border: 2px solid $picked;
  }

  .line {
    position: absolute;
    width: 100px;
    height: 1px;
    background-color: black;
    transform: rotate(-45deg);

    &.reverse {
      transform: rotate(45deg);
    }
  }

  &.md {
    width: 40px;

    .line {
      top: 19.5px;
      left: -30px;
    }
  }

  &.lg {
    width: 50px;

    .line {
      top: 24.5px;
      left: -25px;
    }
  }
}
