.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  &.up {
    border-radius: 20% 20% 0 0;

    .icon {
      margin-top: -7px;
      transform: rotate(90deg);
    }
  }

  &.down {
    border-radius: 0 0 20% 20%;

    .icon {
      margin-bottom: -7px;
      transform: rotate(-90deg);
    }
  }

  &.left {
    border-radius: 20% 0 0 20%;

    .icon {
      margin-left: -7px;
    }
  }

  &.right {
    border-radius: 0 20% 20% 0;

    .icon {
      margin-right: -7px;
      transform: rotate(180deg);
    }
  }

  &.center {
    width: 52px;
    aspect-ratio: 1 / 1;

    .icon {
      margin-top: -0.5px;
      margin-left: -0.5px;
      font-size: 35px;
    }

    .bgTransparent {
      margin-left: -1px;
      position: absolute;
      width: 25px;
      height: 3px;
      background-color: darkslategray;
      transform: rotate(-45deg);
    }
  }

  &.up,
  &.down {
    width: 52px;
    height: 56px;
  }

  &.left,
  &.right {
    width: 56px;
    height: 52px;
  }

  &.lg {
    background-color: lightslategray;
    border: 2px solid;
    border-color: #8da0b4 #576878 #576878 #8da0b4;

    .icon {
      color: darkslategray;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: rgb(98, 113, 129);
        border-color: #00000010 #ffffff20 #ffffff20 #00000010;

        .icon,
        .bgTransparent {
          translate: 0 1px;
        }
      }
    }

    @media (hover: none) and (pointer: coarse) {
      &:active {
        background-color: rgb(98, 113, 129);
        border-color: #00000010 #ffffff20 #ffffff20 #00000010;

        .icon,
        .bgTransparent {
          translate: 0 1px;
        }
      }
    }
  }

  &.sm {
    z-index: 990;
    font-size: 30px;
    color: gray;

    &:active {
      color: dimgray;
    }

    &.up {
      margin-top: -9px;
    }

    &.down {
      margin-bottom: -9px;
    }

    &.left {
      margin-left: -9px;
    }

    &.right {
      margin-right: -9px;
    }
  }
}
