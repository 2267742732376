.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 20px 35px 20px;
  position: relative;

  &.collapse {
    flex-direction: column;
    padding-bottom: 0;
  }
}
