.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 28px;
  margin-left: -30px;

  img {
    width: 22px;
    aspect-ratio: 1 / 1;
  }

  .text {
    padding-top: 3px;
    font-size: 35px;
    font-weight: bold;
    transform: scaleX(1.3);
    letter-spacing: -2px;
  }

  &.collapse {
    gap: 25px;

    img {
      padding-bottom: 2px;
    }

    .text {
      padding-top: 2px;
      font-size: 30px;
      letter-spacing: -2.5px;
    }
  }
}
