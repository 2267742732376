.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 20px;
  height: 95%;
  position: relative;

  .squareGroup {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
    gap: 15px;
  }

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    min-width: 176px;
  }

  &.collapse {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 0;
    padding: 5px 0 7px 0;
    width: 100%;
  }

  .ad {
    position: absolute;
    width: 150px;
    height: 100%;
    top: 0;
    left: 0;
  }
}
