.container {
  position: relative;

  &.none {
    pointer-events: none;
  }

  &.guideBox {
    margin: -3px;
    border: 3px dashed yellow;
    cursor: pointer;

    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: yellow;
      opacity: 0.2;
      z-index: 997;
    }
  }

  &.paletteList {
    position: absolute;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }

  &.arrowUp {
    position: absolute;
    top: 0;
  }

  &.arrowLeft {
    position: absolute;
    left: 0;
  }

  &.arrowRight {
    position: absolute;
    right: 0;
  }

  &.arrowDown {
    position: absolute;
    bottom: 0;
  }
}
