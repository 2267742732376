@use 'style' as *;

.container {
  &.start {
    animation: appear $popup-delay forwards;
  }

  &.end {
    opacity: 1;
    animation: disappear $popup-delay forwards;
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes disappear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
