.container {
  display: flex;
  flex: 1;
  gap: 10px;
  padding: 2px 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .column {
    flex: 1;
    flex-direction: column;
  }
}
