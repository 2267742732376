.container {
  aspect-ratio: 1 / 1;

  * {
    padding: 0;
  }

  .tableWrapper {
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;

    table {
      width: 100%;
      height: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      table-layout: fixed;
    }
  }

  &.bgWhite {
    .tableWrapper {
      background-color: white;
    }
  }

  &.bgBlack {
    .tableWrapper {
      background-color: black;
    }

    &.edit {
      td {
        border: 1px solid darkgray;
      }
    }
  }

  &.preview {
    width: 50px;
    border: 1px solid black;

    &.collapse {
      width: 40px;
    }

    table {
      pointer-events: none;
    }
  }

  &.edit {
    width: 100%;

    td {
      border: 1px solid black;
    }
  }
}
