.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
  height: 95%;
  position: relative;

  .config {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 45%;
    min-width: 150px;

    > div {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &.collapse {
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    height: 100%;
    background-color: #ffffff40;
    border: 1px solid;
    border-color: darkgray dimgray;
    box-sizing: border-box;

    .config {
      flex-direction: row;
      width: 73%;
      z-index: 980;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ad {
    position: absolute;
    width: 150px;
    height: 100%;
    top: 0;
    right: 0;
  }
}
