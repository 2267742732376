@use 'style' as *;

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;

  .main {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .overlay {
      width: 100%;
      height: 100%;
      background-color: #00000030;
    }

    .children {
      position: absolute;
    }
  }

  .ad {
    width: 100%;
    height: (100vw / 4);
    max-height: 150px;
    z-index: 9999;
  }

  &.start {
    opacity: 0;
    animation: fadeIn $popup-delay forwards;
  }

  &.end {
    opacity: 1;
    animation: fadeOut $popup-delay forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
