.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  min-height: 150px;
  background-color: lightgray;
  border: 2px solid;
  border-color: darkgray dimgray dimgray darkgray;

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: teal;
    padding: 0 10px;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
  }

  .contents {
    display: flex;
    flex: 1;
    align-items: center;
    align-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    padding-bottom: 15px;
    width: 100%;
    box-sizing: border-box;

    .desc {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    .button {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
