.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7e7;
  border: 2px solid;
  border-color: darkgray dimgray dimgray darkgray;

  &.sm {
    width: 30px;
    height: 30px;

    svg {
      font-size: 20px;
    }
  }

  &.md {
    width: 45px;
    height: 45px;

    svg {
      font-size: 30px;
    }
  }

  &.lg {
    padding-top: 1px;
    width: 80px;
    height: 38px;
    font-size: 20px;
  }

  &.disabled {
    color: gray;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &.disabled {
      background-color: #00000010;
      border-color: dimgray darkgray darkgray dimgray;

      &.sm {
        padding-top: 1px;
        height: 29px;
      }

      &.md {
        padding-top: 1px;
        height: 44px;
      }

      &.lg {
        padding-top: 2px;
        height: 37px;
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:active,
    &.disabled {
      background-color: #00000010;
      border-color: dimgray darkgray darkgray dimgray;

      &.sm {
        padding-top: 1px;
        height: 29px;
      }

      &.md {
        padding-top: 1px;
        height: 44px;
      }

      &.lg {
        padding-top: 2px;
        height: 37px;
      }
    }
  }
}
