.container {
  display: flex;
  flex-direction: column;
  min-width: 330px;
  height: 100%;
  overflow-x: auto;
  position: absolute;

  .capture {
    position: absolute;
    top: 0;
    z-index: -999;

    .cover {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: lightgray;
    }
  }
}
