@use 'style' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  left: 10px;
  bottom: 20px;
  z-index: 998;

  .toast {
    padding: 10px 20px;
    max-width: 800px;
    height: auto;
    background-color: black;

    pre {
      color: white;
      white-space: pre-wrap;
    }

    &.start {
      animation: appear $popup-delay forwards;
    }

    &.end {
      opacity: 1;
      animation: disappear $popup-delay forwards;
    }
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes disappear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
